import { Component, OnInit } from '@angular/core';
import { AppConfiguration } from '@config/app-configuration';
import { TranslateService } from '@ngx-translate/core';
import { MessageBusService } from '@services/message-bus/message-bus.service';
import { RestService } from '@services/rest.service';
import { StepperService } from '@services/step-container.service';
import { MessageUtils } from '@utils/message.utils';
import { AnalyticsService } from '@services/analytics.service';
import { AdditionalVerificationService } from './additional-verification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SuccessModalComponent } from '@common/success-modal/success-modal.component';
import { ErrorModalComponent } from '@common/error-modal/error-modal.component';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'smione-additional-verification',
  templateUrl: './additional-verification.component.html',
  styleUrls: ['./additional-verification.component.scss']
})
export class AdditionalVerificationComponent implements OnInit {
  PreferredLanguage:string;
  errorMessage:string = null;
  id:string;
  isCodeValid:boolean= false;
  isSuccess:boolean = false;
  isError:boolean = true;
  componentName : string = 'AdditionalVerification';
  policy:any;
  policyString:any;

  form : FormGroup;
  matchPasswordError : string;
  passwordPolicy:string;
  bsConfig: Partial<BsDatepickerConfig>;
  locale = this.translate.getDefaultLang();
  constructor(  
    private additionalVerificationService:AdditionalVerificationService,
    public stepperService: StepperService,
    private translate: TranslateService,
    public messageBus: MessageBusService,
    private restService: RestService,
    private appConfig: AppConfiguration,
    private analyticsService: AnalyticsService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private localeService: BsLocaleService
    ) 
    { 
      this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', dateInputFormat: 'MM/DD/YY', showWeekNumbers: false });
      this.localeService.use(this.locale);
      this.restService.setDefaultLanguage(); 
      this.PreferredLanguage = this.parseQueryString('lang');
      this.id = this.parseQueryString('id'); 
   
      if(!this.PreferredLanguage)
        this.PreferredLanguage = 'en';
      if(!this.id)
        this.id = "0";
    }

  ngOnInit() {     
    this.verifyLink();    
    this.form = this.fb.group({
      CardLast4Digits : ['',[Validators.required]], 
      DateOfBirth : [null,[Validators.required]], 
      newPassword : ['',[Validators.required, Validators.minLength(8), Validators.maxLength(20)]], 
      confirmPassword : ['',[Validators.required]], 
    })
  }

  get control() { return this.form.controls; }

  parseQueryString(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
 
  verifyLink() {
    this.additionalVerificationService.verifyAccess(this.id).subscribe(
      (res) =>{  
        console.log('res', res); 
        if(res) {              
          this.isCodeValid = true;
          this.getPasswordPolicy();        
        }  
        else {        
          let message = MessageUtils.getErrorMessageForSuccessResponse(res);
          this.errorMessage = message;
          this.isError = true; 
                    
        }  
      },
      error => {   
        const messages = error.error.Messages;
        const message = messages.map(d => d.DisplayText).join('.');
        this.errorMessage = message;  
        this.isError = true;
    });
  }

  getPasswordPolicy(){
    this.additionalVerificationService.getPolicyText().subscribe(res =>{
      this.policy = res['Response'];
      if(this.policy && this.policy.PolicyRules){
        this.policyString = this.policy.PolicyRules.join(' ');
      }
      console.log('policyText', this.policyString);
    },
    (err) => { console.error(err); })
  }

  resetForm() {
    this.form.patchValue({
      CardLast4Digits: '',
      DateOfBirth: '',
      newPassword: '',
      confirmPassword: ''
    })
    this.form.markAsUntouched();
  }

  matchPassword(Password, ConfirmPassword){
    this.matchPasswordError = null; 
    if(Password && ConfirmPassword)
    {      
      if(Password != ConfirmPassword)
      {
        this.matchPasswordError = 'New Password and Confirm Password do not match.'
        this.form.controls['confirmPassword'].setErrors({ notMatched: true });
      }
      else
      {
        this.matchPasswordError = null; 
        this.form.controls['confirmPassword'].setErrors(null);
      }
    }
  }

  get f() { return this.form.controls; }

  submit(formData) {
    this.analyticsService.gtag(this.componentName, 'submit');

    if (this.form.invalid) {
      return;
    }    

    if(formData['newPassword'] != formData['confirmPassword']) {
      this.matchPasswordError = 'New Password and Confirm Password do not match.'
      this.form.controls['confirmPassword'].setErrors({ notMatched: true });
      return;
    }
    else {
      this.matchPasswordError = null; 
      this.form.controls['confirmPassword'].setErrors(null);
    }
    let request = { 
      'ApplicationID' : this.appConfig.appId['web'],
      "CardLast4Digits":formData['CardLast4Digits'],         
      "DateOfBirth": this.formatTheDate(new Date(formData['DateOfBirth'])),         
      "Password": formData['newPassword'],
      "ReferenceID": this.id,
      "language":  this.PreferredLanguage
      };

    console.log(request);
    this.additionalVerificationService.resetPassword(request).subscribe(
      res => {     
        if((res.ResponseCode === 200 || res.ResponseCode === 2000) && res['Response'] == true){ 
          this.resetForm();
          this.isSuccess = true; 
          this.isCodeValid = false;    
          this.isError = false;          
        }
        else{
          let message = MessageUtils.getErrorMessageForSuccessResponse(res);
          this.dialog.open(ErrorModalComponent, { disableClose : true, data : { message : message } });
        }
      },
      error => {
        const messages = error.error.Messages;
        const message = messages.map(d => d.DisplayText).join('.');
        this.dialog.open(ErrorModalComponent, { disableClose : true, data : { message : message } });
      }
    );

  }

  formatTheDate(date){ 
    if(date)
      {
        if((date.getMonth() + 1) <= 9){
          var month = '0'+(date.getMonth() + 1);
        }else{
          month = '' + (date.getMonth() + 1);
        }

        if((date.getDate()) <= 9){
          var day = '0'+(date.getDate());
        }else{
          day = '' + date.getDate();
        }

        return  `${month}/${day}/${date.getFullYear()}`;
      }
  }

  login(){  
    this.analyticsService.gtag(this.componentName, `login`);
    sessionStorage.clear();
    localStorage.removeItem('token');
    this.restService.generateNewIDMLogoutUrl();
  }

   //special characters entering show error message 
   allErrorMessages = true; 
   clearIfInvalid(event: any) {
     const input = event.target;
     const invalidChar = input.value.match(/[^0-9a-zA-Z%[\]{}()`#^*+=_\\|~<>.,?!'\/:;$&@\"-]/g, ''); 
     const errorMessage = document.getElementById('error-message') as HTMLElement;
     const invalidCharElement = document.getElementById('invalid-char') as HTMLElement;
 
     if (invalidChar) {
      input.value = input.value.replace(/[^0-9a-zA-Z%[\]{}()`#^*+=_\\|~<>.,?!'\/:;$&@\"-]/g, ''); 
      this.allErrorMessages = false;
       errorMessage.style.display = 'block';
     } else {
       errorMessage.style.display = 'none';
       this.allErrorMessages = true;
 
     }
   } 
}


