import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../dashboard/settings/settings.service';
import { SecurityResponse } from '../../dashboard/settings/security-questions/security-questions.model';
import { QuickRegistrationService } from './quick-registration.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder,FormGroup, Validators, AbstractControl } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { SuccessModalComponent } from '@common/success-modal/success-modal.component';
import { ErrorModalComponent } from '@common/error-modal/error-modal.component';
import { MessageUtils } from '@utils/message.utils';
import { RestService } from '@services/rest.service';
import { AppConfiguration } from '@config/app-configuration';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from '@login-common/validators/must-match.validator';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'smione-quick-registration',
  templateUrl: './quick-registration.component.html',
  styleUrls: ['./quick-registration.component.scss'],
})
export class QuickRegistrationComponent implements OnInit {
  submmited : boolean = false; 
  isPwdUn:boolean = false;
  lstQuestion: SecurityResponse[];
  lstQuestion1:SecurityResponse[];
  lstQuestion2:SecurityResponse[];
  isCodeValid:boolean= false;  // make it true only for local testing
  errorMessage:string="";
  form:FormGroup;
  code;
  applicationID;
  policyText;
  public PreferredLanguage: string;
  componentName : string = 'QuickRegistration';
  constructor(
              private settingsService:SettingsService,
              private qrService:QuickRegistrationService,
              private translate: TranslateService,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private restService: RestService,
              private appConfig: AppConfiguration,
              private route: ActivatedRoute,
              private router: Router,
              private analyticsService: AnalyticsService
              ) 
    {
      localStorage.removeItem("codeType");
      this.restService.setDefaultLanguage();
      this.PreferredLanguage = this.parseQueryString('lang');
      this.code = this.parseQueryString('id');  

      if(!this.PreferredLanguage)
        this.PreferredLanguage = 'en';
      if(!this.code)
        this.code = "0";
      this.applicationID = this.appConfig.appId['web'].toString();
  }

  parseQueryString(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  ngOnInit() {
    this.verifyLink();   
    this.form = this.fb.group({
      question1 : ['',[Validators.required]], 
      question2 : ['',[Validators.required]], 
      answer1 : ['',[Validators.required, Validators.minLength(3)]], 
      answer2 : ['',[Validators.required, Validators.minLength(3)]],  
      cardno : ['',[Validators.required]],  
      password: ['', [Validators.required] ],
      confirmPassword: ['', [Validators.required,  Validators.maxLength(20)] ], 
      username:['', [Validators.required, Validators.minLength(7), Validators.maxLength(15)] ],
    },
      { validator: MustMatch('password', 'confirmPassword') });
   
    $(document).on("contextmenu",  function() {        
      //return false;
    });
  }
  get fval() { return this.form.controls; }

  verifyLink()
  {
    this.qrService.verifyLink(this.code).subscribe(
      (res) =>{
        if(res)
        { this.isCodeValid = true;
          this.getPasswordPolicy();
          this.getSecurityQuestion();
        }  
        else
        {
          
          let message = MessageUtils.getErrorMessageForSuccessResponse(res);
          this.dialog.open(ErrorModalComponent, { disableClose : true, data : { message : message } });
        }  
      },
      error => { 
       
        const messages = error.error.Messages;
        const message = messages.map(d => d.DisplayText).join('.');
        this.errorMessage= message;
       
      });
  }

  getPasswordPolicy()
  {
    this.qrService.getPasswordPolicy(this.PreferredLanguage, this.applicationID ).subscribe(
      (res) =>{
        if(res)
        {
          this.policyText = res.PolicyText ;
        }  
        else
        {
        
          let message = MessageUtils.getErrorMessageForSuccessResponse(res);
          this.dialog.open(ErrorModalComponent, { disableClose : true, data : { message : message } });
        }  
      },
      err => {  
        console.error(err);
      });
  }

  getSecurityQuestion() 
  { 
    this.qrService.getSecurityQuestion(this.PreferredLanguage).subscribe(
    (res) =>{
      if(res){
        this.lstQuestion = res;
        this.lstQuestion1 = res;
        this.lstQuestion2 = res; 
      }  
      else
      {
        let message = MessageUtils.getErrorMessageForSuccessResponse(res);
        this.dialog.open(ErrorModalComponent, { disableClose : true, data : { message : message } });
      }   
    },
    err => {  
      console.error(err);
    });
  }

  onQuestion2Change(question2)
  {
    this.analyticsService.gtag(this.componentName, `onQuestion2Change`);
    let question1 = this.form.controls['question1'].value;
    this.lstQuestion1 = this.lstQuestion.filter(item => item.ID != question2);
    if (question1)
      this.form.controls['question1'].setValue(question1);
  }

  onQuestion1Change(question1)
  {
    this.analyticsService.gtag(this.componentName, `onQuestion1Change`);
    let question2 = this.form.controls['question2'].value;
    this.lstQuestion2 = this.lstQuestion.filter(item => item.ID != question1);
    if (question2)
      this.form.controls['question2'].setValue(question2);
  }
  
  onClear()
  {
     this.restService.generateNewIDMLogoutUrl();
  }

  onSubmit(form){    
    this.analyticsService.gtag(this.componentName, `onSubmit`);
    this.submmited = true;
    if (this.form.invalid) {
        return;
    } 
    var objSecurity= {
      "SecurityQuestion1ID": form['question1'],
      "SecurityQuestion2ID": form['question2'],
      "SecurityQuestion1Value":  form['answer1'],
      "SecurityQuestion2Value":form['answer2'] ,  
      "ApplicationID": this.appConfig.appId['web'].toString() ,
      "CardLast4Digits": form['cardno'],
      "Code": this.code,
      "Password": form['password'],
      "PreferredLanguage": this.PreferredLanguage,     
      "UserName": form['username'],
      };

      this.qrService.quickRegister(objSecurity).subscribe(
        res => {
          if((res.ResponseCode === 200 || res.ResponseCode === 2000)){ 
            this.submmited = false;
            this.translate.get('DASHBOARD.SETTINGS.SQ.SUCCESS').subscribe((translatedValue)=>{ 
              const dialogRef = this.dialog.open(SuccessModalComponent, { 
                disableClose : true,
                data : { message : translatedValue } 
              });        
            });  
            this.restService.generateNewIDMLogoutUrl();
          }else{
            let message = MessageUtils.getErrorMessageForSuccessResponse(res);
            this.dialog.open(ErrorModalComponent, { disableClose : true, data : { message : message } });
            //this.resetForm();
          }
        },
        err => { console.error(err);}
      );
  }

  chkPwdUn()
  {  
    this.isPwdUn = false; 
    if(!this.form.controls['password'].errors)
    {
      if ( this.form.controls['password'].value!="" && (this.form.controls['password'].value ==  this.form.controls['username'].value)) 
      {
        this.isPwdUn = true; 
        this.form.controls['password'].setErrors({'incorrect': true});
      }
      else {
        this.form.controls['password'].setErrors(null);
      }
    }
  }
  resetForm(){
    this.submmited = false;
    this.form.patchValue({
      question1: '',
      question2: '',
      answer1: '',
      answer2: '',
      cardno: '',
      password: '',
      confirmPassword: '',
      username: ''
    })
  }

   //special characters entering show error message 
   allErrorMessages = true; 
   clearIfInvalid(event: any) {
     const input = event.target;
     const invalidChar = input.value.match(/[^0-9a-zA-Z%[\]{}()`#^*+=_\\|~<>.,?!'\/:;$&@\"-]/g, ''); 
     const errorMessage = document.getElementById('error-message') as HTMLElement;
     const invalidCharElement = document.getElementById('invalid-char') as HTMLElement;
 
     if (invalidChar) {
      input.value = input.value.replace(/[^0-9a-zA-Z%[\]{}()`#^*+=_\\|~<>.,?!'\/:;$&@\"-]/g, ''); 
      invalidCharElement.textContent = invalidChar[0];
       this.allErrorMessages = false;
       errorMessage.style.display = 'block';
     } else {
       errorMessage.style.display = 'none';
       this.allErrorMessages = true;
 
     }
   } 
}
